import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from '../configureStore'
import { ADMIN_ROLE, AFFILIATE_ROLE, DESIGN_ROLE, DEV_ROLE, FINANCE_ROLE, TEACHER_ROLE } from 'src/util/constants'

type StaffRolesHook = [
  boolean,
  () => void,
  boolean,
  string[]
]

type validRoles = 'admin' | 'dev' | 'teacher' | 'finance' | 'design' | 'affiliate'

const useStaffRoles = (...limitToRoles: validRoles[]): StaffRolesHook => {
  // const [isStaffRole, setIsStaffRole] = useState<boolean>(false)
  const isSignedIn = useSelector((rootState: RootState) => rootState.auth.isSignedIn)
  const roles: string[] = useSelector((state: RootState) => state.auth?.roles)

  const staffRoles = [ADMIN_ROLE, DEV_ROLE, TEACHER_ROLE, FINANCE_ROLE, DESIGN_ROLE, AFFILIATE_ROLE]

  const isStaffRole = useMemo(() => {
    if (!isSignedIn) return false

    let hasValidRole

    // prevent affiliates from being mistaken as having higher access for pages
    // where affiliates weren't considered as a role in the future
    if (roles?.length === 1 && roles?.[0] === 'affiliate') return false

    if (limitToRoles?.length) {
      hasValidRole = !!limitToRoles.reduce((previous, current): boolean => {
        return roles?.includes(current) ? true : previous
      }, false)
    } else {
      hasValidRole = !!roles?.find((r) => staffRoles.includes(r))
    }

    return hasValidRole
  }, [roles, isSignedIn])

  const isAffiliate = useMemo(() => {
    return roles?.includes('affiliate')
  }, [roles, isSignedIn])

  /* eslint-disable no-console */
  const logToConsole = (type = 'log', ...args: string[]) => {
    if (isStaffRole && !!args) {
      switch (type) {
        case 'error':
          args.forEach((m) => console.error(m))
          console.trace()
          break
        case 'info':
          args.forEach((m) => console.info(m))
          break
        case 'warn':
          args.forEach((m) => console.warn(m))
          break
        case 'group':
          console.group(args[0])
          args.forEach((m, i) => i > 0 && console.log(m))
          console.groupEnd()
          break
        case 'group-collapsed':
          console.groupCollapsed(args[0])
          args.forEach((m, i) => i > 0 && console.log(m))
          console.groupEnd()
          break
        case 'assert':
          args.forEach((m) => console.assert(m))
          break
        default:
          args.forEach((m) => console.log(m))
      }
    }
  }
  /* eslint-enable */

  return [isStaffRole, logToConsole, isAffiliate, roles]
}

export default useStaffRoles
