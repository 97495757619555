import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import axios from 'src/api'

import useAffiliate from './useAffiliate'
import useCampaigns from './useCampaigns'
import useGrandfather from './useGrandfather'

import { RootState } from 'src/configureStore'

const getPromos = async () => {
  try {
    const res = await axios.get('/user/promos')
    return res.data.promos
  } catch (e) {
    return {}
  }
}

const usePromos = () => {
  const { _id } = useSelector((state: RootState) => state.auth)

  const { affiliate } = useAffiliate()
  const { campaigns } = useCampaigns()
  const { grandfather } = useGrandfather()
  const { data: promos } = useQuery({ queryKey: ['promos', _id], queryFn: getPromos })

  const isNewYearsSale = false

  return { affiliate, campaigns, grandfather, isNewYearsSale, promos: promos || {} }
}

export default usePromos
