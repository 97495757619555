/* eslint-disable no-multi-spaces */
export const VAULT_ACCESS          = 'vault'
export const KANSAI_COURSE         = 'kansai'
export const PREMIUM_ANNUAL        = 'premium-annual'
export const PREMIUM_MONTHLY       = 'premium-monthly'
export const PREMIUM_MONTHLY_2022  = 'premium-monthly-2022'
export const PREMIUM_ANNUAL_2022   = 'premium-annual-2022'
export const EARLY_SUPPORTER       = 'early-supporter'
export const INTERNAL_AFF_MONTH    = 'internal-aff-monthly'
export const INTERNAL_AFF_YEAR     = 'internal-aff-annual'
export const EMAIL_CAMPAIGN        = 'email-campaign-2022'
export const EMAIL_CAMPAIGN_ANNUAL = 'email-campaign-2022-annual'
export const PREMIUM_AFFILIATE     = 'premium-aff-monthly'
export const YJ_PREMIUM_MONTHLY    = 'yj-premium-monthly'
export const YJ_PREMIUM_ANNUAL     = 'yj-premium-annual'
export const PREMIUM_NEW_YEARS     = 'premium-annual-half'
export const GIFT_PLAN             = 'gift-plan'
export const PREMIUM_MONTHLY_50    = 'premium-monthly-50'
export const PREMIUM_ANNUAL_50     = 'premium-annual-50'

export const ADMIN_ROLE            = 'admin'
export const DEV_ROLE              = 'dev'
export const TEACHER_ROLE          = 'teacher'
export const DESIGN_ROLE           = 'design'
export const FINANCE_ROLE          = 'finance'
export const AFFILIATE_ROLE        = 'affiliate'

export const CLOUDFRONT_BASE = 'https://d32tv5vz8ua1sf.cloudfront.net'

export const SUBSCRIPTION_PLANS = [
  PREMIUM_ANNUAL, PREMIUM_MONTHLY, PREMIUM_MONTHLY_2022,
  PREMIUM_ANNUAL_2022, INTERNAL_AFF_MONTH, INTERNAL_AFF_YEAR,
  EMAIL_CAMPAIGN, EMAIL_CAMPAIGN_ANNUAL, PREMIUM_AFFILIATE,
  YJ_PREMIUM_MONTHLY, YJ_PREMIUM_ANNUAL, PREMIUM_NEW_YEARS,
  GIFT_PLAN, PREMIUM_MONTHLY_50, PREMIUM_ANNUAL_50
]
export const STAFF_ROLES = [
  ADMIN_ROLE, DEV_ROLE, TEACHER_ROLE, DESIGN_ROLE, FINANCE_ROLE, AFFILIATE_ROLE
]
/* eslint-enable */
