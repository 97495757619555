// a hook just for the progressification/version selection of text based on a record
// no sound functionality etc, which has been split into separate hooks
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Record } from 'src/types'

import { JP_SpanStyles } from 'src/components/fz_courses/sound_icon/SoundIcon.types'
import RomajiTextSpan from 'src/components/fz_courses/sound_icon/RomajiTextSpan'
import KanjiTextSpan from 'src/components/fz_courses/sound_icon/KanjiTextSpan'
import ProgressiveTextSpan from 'src/components/fz_courses/sound_icon/ProgressiveTextSpan'
import HirakataTextSpan from 'src/components/fz_courses/sound_icon/HirakataTextSpan'

import useMobile from 'src/hooks/useMobile'

const boldFont = 'Noto Sans JP Bold, Milliard Bold'
const normalFont = 'Noto Sans JP, Yu Gothic UI Regular, Milliard Book, Helvetica, sans-serif'

const useSoundText = (record: Record, options: any) => {
  const isMobile = useMobile()
  const colorized_furi = useSelector((state: any) => state.auth.colorized_furi)
  const {
    format, forceMode, main_default, inHeader, bold, yellow,
    color, fontSize, fontFamily, kanjiData, progressive_data, resizeAfter,
    holdsFuri, setHoldsFuri, furi_opacity, customEnabled, customSettings, bonusData, widget, formatText,
    location
  } = options

  const { bold: b, italic: i, underline: u, color: c, highlight: h } = format || {}
  const ja_span_styles: JP_SpanStyles = {
    fontFamily: fontFamily ? fontFamily : (inHeader || bold) ? boldFont : normalFont,
    fontWeight: b ? 900 : (inHeader ? 'unset' : (bold ? 900 : 200)),
    color: c ? c : yellow ? 'yellow' : color ? color : 'unset',
    fontStyle: i ? 'italic' : '',
    textDecoration: u ? 'underline' : '',
    backgroundColor: h || '',
    fontSize: fontSize ? `${fontSize}` : '',
  }

  if (format?.strikethrough) {
    ja_span_styles.textDecoration = `line-through ${ja_span_styles.textDecoration}`
  }

  const versionSelected = useMemo(() => {
    return {
      romaji: (
        <RomajiTextSpan
          record={record}
          isMobile={isMobile}
          styles={ja_span_styles}
          resizeAfter={resizeAfter}
          bonusData={bonusData}
          formatText={formatText}
        />
      ),
      kanji: (
        <KanjiTextSpan
          kanjiData={kanjiData}
          record={record}
          holdsFuri={holdsFuri}
          resizeAfter={resizeAfter}
          isMobile={isMobile}
          furi_opacity={furi_opacity}
          colorized_furi={colorized_furi}
          progressive_data={progressive_data}
          bonusData={bonusData}
          styles={ja_span_styles}
          main_default={main_default}
          setHoldsFuri={setHoldsFuri}
          formatText={formatText}
        />
      ),
      progressive: (
        <ProgressiveTextSpan
          record={record}
          isMobile={isMobile}
          styles={ja_span_styles}
          resizeAfter={resizeAfter}
          progressive_data={progressive_data}
          bonusData={bonusData}
          custom_enabled={customEnabled}
          custom_settings={customSettings}
          furi_opacity={furi_opacity}
          widget={widget}
          formatText={formatText}
        />
      ),
      hirakata: (
        <HirakataTextSpan
          record={record}
          isMobile={isMobile}
          resizeAfter={resizeAfter}
          styles={ja_span_styles}
          formatText={formatText}
        />
      )
    }[location?.pathname?.includes('kanji') ? 'kanji' : (forceMode || main_default || 'progressive')]
  }, [record, isMobile, forceMode, main_default, colorized_furi, location])

  return { soundText: versionSelected }
}

export default useSoundText
