import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'src/configureStore'

import {
  AcademicCapSolid,
  ChartBarSolid,
  FilmSolid,
  TemplateSolid,
  TerminalSolid,
  TranslateSolid
} from '@graywolfai/react-heroicons'
import { Icon } from 'semantic-ui-react'
import { Transition } from '@headlessui/react'
import DropdownStyles from '../../styles/DropdownStyles'

import useStaffRoles from 'src/hooks/useStaffRoles'
import { AFFILIATE_ROLE } from 'src/util/constants'

interface DropdownMenuProps {
  isOpen: boolean
}

const AdminDropdownMenu: React.FC<DropdownMenuProps> = ({ isOpen }) => {
  const [willOpen, setWillOpen] = useState(true)
  const roles: string[] = useSelector((state: RootState) => state.auth?.roles)
  const [isStaffRole] = useStaffRoles()
  const [isAffiliate] = useStaffRoles(AFFILIATE_ROLE)
  
  const handleBeforeEnter = () => setWillOpen(false)
  const handleAfterLeave = () => setWillOpen(true)

  const admin_only = [
    // ['Course Builder', '/admin/courses'],
    {
      name: 'Admin Panel',
      route: '/admin',
      icon: <TerminalSolid height='25px' width='25px' className='tw-inline tw-fill-blue' fill='blue' />,
    },
    {
      name: 'Site Metrics',
      route: '/admin#metrics',
      icon: <ChartBarSolid height='25px' width='25px' className='tw-inline tw-fill-blue' fill='blue' />,
    },
    {
      name: 'Bug Reports',
      route: '/admin#bug-reports',
      icon: <span className="s-ui"><Icon name='bug' /></span>,
    },
    {
      name: 'Vault Admin',
      route: '/admin/vault',
      icon: <FilmSolid height='25px' width='25px' className='tw-inline tw-fill-blue' fill='blue' />,
    },
    {
      name: 'かな Poster',
      route: '/dev/poster?view=japanese',
      icon: <TranslateSolid height='25px' className='tw-inline tw-fill-blue' fill='blue' />,
    },
    {
      name: '한글 Poster',
      route: '/dev/poster?view=hangeul',
      icon: <TemplateSolid height='25px' className='tw-inline tw-fill-blue' fill='blue' />,
    },
    {
      name: 'Tools',
      route: '/tools',
      icon: <span className="s-ui"><Icon name='wrench' /></span>,
    },
    {
      name: 'Playground',
      route: '/dev/playground',
      icon: <span className='s-ui'><Icon name='lightbulb' /></span>,
    }
  ]

  const teacher_only = [
    {
      name: 'Teacher Tools',
      route: '/teacher',
      icon: <AcademicCapSolid height='25px' className='tw-inline tw-fill-blue' fill='blue' />,
    },
  ]

  if (!isStaffRole) return <></>

  return (
    <DropdownStyles willOpen={willOpen}>
    <Transition
      show={isOpen}
      enter='tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700'
      enterFrom='tw-translate-y-[-100px] tw-opacity-0'
      enterTo='tw-translate-y-0 tw-opacity-full'
      leave='tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700'
      leaveFrom='tw-translate-y-0 tw-opacity-full'
      leaveTo='tw-translate-y-[-100px] tw-opacity-0'
      beforeEnter={() => handleBeforeEnter()}
      afterLeave={() => handleAfterLeave()}
    >
      <div className='staff-routes'>
        {roles?.includes('admin')
          && <div>
            <h3>Admin Links</h3>
            <div className='admin-links'>
              {admin_only.map((a_link) => (
                <Link
                  key={a_link.name}
                  to={a_link.route}
                >
                  <span className="icon">{a_link.icon}</span>
                  <span className="route-name">{a_link.name}</span>
                </Link>
              ))}
            </div>
          </div>
        }
        <div>
          {(roles?.includes('admin') || isAffiliate) && (
            <div className='admin-links'>
              <Link to='/affiliate-panel'>
                <span className='icon'>
                  <Icon name='handshake outline' />
                </span>
                <span className='route-name'>Affiliate Panel</span>
              </Link>
            </div>
          )}
          {roles?.includes('teacher')
            && <div className='teacher-links'>
              <h3>Teacher Links</h3>
              {teacher_only.map((a_link) => (
                <Link
                  key={a_link.name}
                  to={a_link.route}
                >
                  <span className="icon">{a_link.icon}</span>
                  <span className="route-name">{a_link.name}</span>
                </Link>
              ))}
            </div>
          }
        </div>
      </div>
    </Transition>
    </DropdownStyles>
  )
}

export default AdminDropdownMenu
