import styled, { css } from 'styled-components'

const wrapAtIconStyles = css`
  display: inline-grid;
  align-items: baseline;
  grid-template-columns: auto 1fr;
  text-align: left; // added because of structure widget bug
`

const soundQuestionStyles = ({ isMobile }) => css`
  display: grid;
  place-items: center;
  margin: ${isMobile ? '16px 0 0' : '32px 0 16px'};
`

const blue = '#0e24eb'
const red = '#eb0e4c'
const invertedBlue = '#c3c9f7'
const invertedRed = '#fca9c1'

type SoundIconStyleProps = {
  noPopup?: boolean
  holdsFuri?: boolean
  noWrap?: boolean
  wrapAtIcon?: boolean
  white?: boolean
  playing?: boolean
  inverted?: boolean
  progSetting?: string
  furiOpacity?: number
  browser?: string
  os?: string
  noFuri?: boolean
  huge?: boolean
  soundQuestion?: boolean
  swapFuriColors?: boolean
  presentation?: boolean
  highlightSound: boolean
  isMobile: boolean
}

const SoundIconStyleWrapper = styled.blockquote<SoundIconStyleProps>`
  cursor: ${({ noPopup }) => (noPopup ? '' : 'pointer')};
  ${({ holdsFuri }) => holdsFuri && 'padding-top: 8px; margin-top: -4px; padding-bottom: 4px;'}
  transition: all 0.1s ease-in;
  margin: 0;
  display: inline;
  // word-break: ${(props) => (props.progSetting !== 'hirakata' ? 'keep-all' : '')};
  /* stops Chinese / Japanese / Korean text from breaking-mid word */


  /*
    targets the triangle icon in SoundIcon
  */
  .FZPlayButton.play:before {
    position: relative !important;
    left: 0.5px !important;
    top: 0.3px !important;
  }
  
  
  &:focus-within .sound {
    ${({ huge, presentation }) => !huge && !presentation && `background-color: #c1d7d3;`}
    color: var(--blue-gray);
    border-radius: 3px;
    padding-left: 4px;
    margin-left: -4px;
    padding-right: 2px;
    margin-right: -2px;
    ${({ holdsFuri, wrapAtIcon }) => (holdsFuri && !wrapAtIcon) && 'padding-top: 8px; margin-top: -4px; padding-bottom: 4px;'}
  }
  &:focus-within rt {
    opacity: ${({ noFuri }) => !noFuri && 1};
  }

  .sound {
    border-radius: 3px;
    color: ${({ white, playing }) => white ? '#fcfaff' : (playing && 'var(--blue-gray)')};
    background-color: ${({ playing, white, huge, soundQuestion, presentation }) => (playing && !huge && !soundQuestion && !presentation) && (white ? 'rgba(0,0,0,.35)' : '#f5ecbb')};
    /* ${({ progSetting }) => progSetting === 'hirakata' ? `
        display: inline-flex;
        align-items: baseline;
    ` : ''}; */
    ${({ playing, holdsFuri, wrapAtIcon, highlightSound }) => (((playing || highlightSound)) && !wrapAtIcon && holdsFuri) ? `
      padding-top: 8px;
      margin-top: -4px;
      padding-left: 4px;
      margin-left: -4px;
      padding-right: 2px;
      margin-right: -2px;
    ` : ''}

    ${({ wrapAtIcon }) => wrapAtIcon && wrapAtIconStyles}
    ${({ soundQuestion }) => soundQuestion && soundQuestionStyles}
    ${({ highlightSound }) => highlightSound && css`background-color: rgba(0, 0, 0, .75); color: white;`}
  }

  /* .sound-span {
      white-space: break-spaces;
      word-wrap: break-word;

      &:before {
          content: "";
          position: relative;
          height: 1px;
          width: 21px;
          display: inline-block;
          padding-left: 21px !important;
          margin-left: -21px;
      }
  } */

  i {
    min-width: 21px;
    min-height: 21px;
  }

  rt {
    font-family: 'BIZ UDPGothic';
    opacity: ${(props) => props.noFuri ? 0 : props.furiOpacity / 100};
    transition: all 0.1s ease-in;
    transform: ${({ browser, os }) => {
    /* eslint-disable indent */
      if (browser === 'chrome') {
        if (os === 'macos') return 'translateY(-2px);'
      }
      return 'translateY(0px);'
    }};
    // thanks for being you, Firefox
    margin-bottom: ${({ browser, os }) => {
      if (browser === 'firefox') {
        if (os === 'macos') return '1.5px;'
        if (os === 'windows') return '-4px;'
      }
      return '0px;'
    }};
    /* eslint-enable */

    /*
    // prevent furigana from creating space around a kana,
    // squish kana together, 
    // recenter over the kanji
    
    width: 1px;
    white-space: nowrap;
    letter-spacing: -1px;
    transform: translateX(-5px);
    */
  }
  ruby:hover rt, rt:hover { opacity: ${({ noFuri }) => !noFuri && 1}; }
  .furi-on-hover rt { opacity: 0; }
  .furi-on-hover:hover rt { opacity: 1; }
  .furi-onyomi {
    color: ${({ inverted, swapFuriColors }) => swapFuriColors 
      ? inverted
        ? invertedRed
        : red
      : inverted 
        ? invertedBlue
        : blue
    };
    text-shadow: 0 0 1px #fff;
  }
  .furi-kunyomi {
    color: ${({ inverted, swapFuriColors }) => swapFuriColors
      ? inverted 
        ? invertedBlue
        : blue
      : inverted
        ? invertedRed
        : red
    };
    text-shadow: 0 0 1px #fff;
  }
  .furi-exception {
    color: ${({ inverted }) => inverted ? '#bdffd6' : '#1cc75e'};
    text-shadow: 0 0 1px #fff;
  }
  /* .visible rt { opacity: 1 !important; } */
`

export default SoundIconStyleWrapper
