import React, { useContext, useEffect, useState } from 'react'

import { SubscriptionContext } from 'src/contexts/SubscriptionContext'

import useIsPaying from 'src/hooks/useIsPaying'

// premium-monthly and premium-annual primary keys
// various plans are secondary keys for specific prices
const PLAN_INFO = {
  'premium-monthly': {
    premium: {
      planName: 'Premium Access Monthly',
      price: '$9.97',
      paypal: process.env.REACT_APP_PAYPAL_PLAN,
      priceId: process.env.REACT_APP_PREMIUM_SUB_PRICE,
      planId: 'premium-monthly',
      interval: 'mo',
      base: '$9.97'
    },
    // revised higher pricing post-beta
    'premium-2022': {
      planName: 'Premium Access Monthly',
      price: '$12.97',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_2022,
      priceId: process.env.REACT_APP_PREMIUM_SUB_PRICE_2022,
      planId: 'premium-monthly-2022',
      interval: 'mo',
      base: '$12.97'
    },
    // new years was annual only, normal pricing for monthly
    'premium-half': {
      planName: 'Premium Access Monthly',
      price: '$12.97',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_2022,
      priceId: process.env.REACT_APP_PREMIUM_SUB_PRICE_2022,
      planId: 'premium-monthly-2022',
      interval: 'mo',
      base: '$12.97'
    },
    // affiliate pricing
    'internal-aff': {
      planName: 'Premium Access Monthly FZ Affiliate',
      price: '$8.43',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_INT_AFF_MONTH,
      priceId: process.env.REACT_APP_SUB_PRICE_INTERNAL_AFF_MONTH,
      planId: 'internal-aff-monthly',
      interval: 'mo',
      base: '$12.97'
    },
    // 30% campaign pricing
    'email-campaign-2022': {
      planName: 'Email Campaign 2022',
      price: '$8.97',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_EMAIL_2022,
      priceId: process.env.REACT_APP_PREMIUM_SUB_PRICE_EMAIL_CAMPAIGN_2022,
      planId: 'email-campaign-2022',
      interval: 'mo',
      base: '$12.97'
    },
    // 50% first month affiliate pricing
    'premium-aff': {
      planName: 'Premium Access Monthly Affiliate',
      price: '$6.49',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_AFFILIATE,
      priceId: process.env.REACT_APP_SUB_PRICE_AFFILIATE,
      planId: 'premium-aff-monthly',
      interval: 'mo',
      base: '$12.97'
    },
    // 50% off for winback promotion
    'premium-50': {
      planName: 'Premium Access Monthly (50%)',
      price: '$6.00',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_MONTHLY_HALF,
      priceId: process.env.REACT_APP_PREMIUM_SUB_PRICE_MONTHLY_HALF,
      planId: 'premium-monthly-50',
      interval: 'mo',
      base: '$12.97'
    },
  },
  'premium-annual': {
    // original beta pricing
    premium: {
      planName: 'Premium Access Annual',
      price: '$99.70',
      priceId: process.env.REACT_APP_PREMIUM_SUB_PRICE_ANNUAL,
      priceIdAffInt: process.env.REACT_APP_SUB_PRICE_INTERNAL_AFF_YEAR,
      priceIdAff: process.env.REACT_APP_SUB_PRICE_AFFILIATE,
      paypal: process.env.REACT_APP_PAYPAL_PLAN_ANNUAL,
      planId: 'premium-annual',
      interval: 'yr',
      base: '$99.70'
    },
    'premium-2022': {
      planName: 'Premium Access Annual',
      price: '$129.70',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_2022_ANNUAL,
      priceId: process.env.REACT_APP_PREMIUM_SUB_PRICE_ANNUAL_2022,
      planId: 'premium-annual-2022',
      interval: 'yr',
      base: '$129.70'
    },
    'internal-aff': {
      planName: 'Premium Access Annual FZ Affiliate',
      price: '$93.88',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_INT_AFF_YEAR,
      priceId: process.env.REACT_APP_SUB_PRICE_INTERNAL_AFF_YEAR,
      planId: 'internal-aff-annual',
      interval: 'yr',
      base: '$129.70'
    },
    'email-campaign-2022': {
      planName: 'Email Campaign 2022 Annual',
      price: '$89.70',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_EMAIL_2022_ANNUAL,
      priceId: process.env.REACT_APP_PREMIUM_SUB_PRICE_EMAIL_CAMPAIGN_2022_ANNUAL,
      planId: 'email-campaign-2022-annual',
      interval: 'yr',
      base: '$129.70'
    },
    'premium-aff': {
      planName: 'Premium Access Annual Affiliate',
      price: '$115.00',
      priceId: process.env.REACT_APP_SUB_PRICE_AFFILIATE_ANNUAL,
      planId: 'premium-aff-annual',
      interval: 'yr',
      base: '$129.70'
    },
    // half-off annual pricing for new years sale
    'premium-half': {
      planName: 'Premium Access Annual (New Years Sale)',
      price: '$77.00',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_NEW_YEARS,
      priceId: process.env.REACT_APP_PREMIUM_SUB_PRICE_NEW_YEARS,
      planId: 'premium-annual-half',
      interval: 'yr',
      base: '$129.70'
    },
    'premium-50': {
      planName: 'Premium Access Annual (50%)',
      price: '$60.00',
      paypal: process.env.REACT_APP_PAYPAL_PLAN_ANNUAL_HALF,
      priceId: process.env.REACT_APP_PREMIUM_SUB_PRICE_ANNUAL_HALF,
      planId: 'premium-annual-50',
      interval: 'yr',
      base: '$129.70'
    },
  },
}

const usePlan = (chosenPlan, promoData = null) => {
  const [plan, setPlan] = useState(PLAN_INFO[chosenPlan]['premium-2022'])
  const { affiliate, grandfather, isNewYearsSale, campaigns, promos } = promoData || useContext(SubscriptionContext)
  const campID = campaigns?.[0]?.identifier
  const { paying, yj, personalExpiry: expiry, loading: payingLoading } = useIsPaying()
  const yjPromoValid = !payingLoading && !paying && yj && (expiry && new Date(expiry) > new Date())

  // KEYS
  // premium - normal pricing (pre-beta)
  // premium-2022 - post-beta pricing
  // internal-aff - internal affiliate pricing
  // email-campaign-2022 - 30% off campaign pricing
  // premium-aff - affiliate pricing
  // premium-50 - 50% off first month pricing
  // premium-half - annual-only new years discount
  useEffect(() => {
    if (isNewYearsSale) {
      setPlan(PLAN_INFO[chosenPlan]['premium-half'])
    } else if (promos?.p6) {
      setPlan(PLAN_INFO[chosenPlan]['premium-50'])
    } else if (affiliate && !affiliate?.internal) {
      setPlan(PLAN_INFO[chosenPlan]['premium-aff'])
    } else if (grandfather || promos?.p90 || campID || affiliate?.internal || yjPromoValid) {
      // TODO: add logic to convert grandfather plan to selectable plan
      setPlan(PLAN_INFO[chosenPlan]['email-campaign-2022'])
    } else {
      setPlan(PLAN_INFO[chosenPlan]['premium-2022'])
    }
  }, [plan, chosenPlan, affiliate, grandfather, isNewYearsSale, campaigns, promos])

  return plan
}

export default usePlan
