import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Redirect, Route } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { TOGGLE_V8_PROGRESSIVE } from 'src/actions/types'
import axios from '../../api'
import useAPI from 'src/hooks/useAPI'

import LoginModal from '../modals/LoginModal'
import { AxiosError } from 'axios'
import useSubscribed from 'src/hooks/useSubscribed'
import useStaffRoles from 'src/hooks/useStaffRoles'
import { loadSubs } from 'src/actions'
import { Loader } from '@mantine/core'

const valid = [
  'premium-monthly', 'premium-annual', 'premium-monthly-2022', 'premium-annual-2022',
  'internal-aff-monthly', 'internal-aff-monthly', 'email-campaign-2022',
  'email-campaign-2022-annual', 'premium-aff-monthly', 'yj-premium-monthly', 'yj-premium-annual',
  'premium-annual-half', 'gift-plan', 'premium-monthly-50', 'premium-annual-50',
]

const subscriptionCore = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState<boolean>()
  const [loadingFailed, setLoadingFailed] = useState<boolean>()
  const [isLocked, setIsLocked] = useState<boolean>()
  const { isPremiumSubscribed } = useSubscribed()
  const [isStaffRole] = useStaffRoles()
  const [resActive, loadingSubs, reqActive] = useAPI('/subscriptions/current')
  const alreadySubs = rest.subs
  const location = useLocation()
  const dispatch = useDispatch()

  // const ping = useCallback(async () => {
  //   try {
  //     await axios.get('/user/ping', { params: { path: location.pathname } })
  //   } catch (err) {
  //     const error = err as AxiosError
  //     if (error.response?.status === 403) {
  //       setLoadingFailed(true)
  //     }
  //   }
  // }, [location.pathname])

  const isSubbed = valid.includes(alreadySubs?.[0]?.identifier)
  // console.log(alreadySubs, isSubbed)

  useEffect(() => {
    if (!loadingSubs) reqActive()
  }, [location.pathname])

  useEffect(() => {
    if (!resActive) return

    if (resActive.subs?.length > 0) {
      rest.loadSubs(resActive.subs.map((sub) => ({ identifier: sub.plan?.identifier })))
    }

    // make sure v8 progressive is set during sublesson load
    dispatch({
      type: TOGGLE_V8_PROGRESSIVE,
      payload: resActive.v8_progressive
    })
  }, [resActive])

  const checkLocked = async () => {
    let course, lesson
    // if (loadingFailed === false) return

    let shouldLock
    let didLoadingFail

    if (rest?.computedMatch?.params) {
      course = rest.computedMatch.params.course
      lesson = rest.computedMatch.params.lesson
    } else return

    try {
      const res = await axios.get(`/course/${course}/${lesson}/locked`)
      shouldLock = res.data.isLocked
      didLoadingFail = false
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.message)
      console.error(error?.message)

      shouldLock = true
      didLoadingFail = true
    } finally {
      setLoadingFailed(didLoadingFail)
      setIsLocked(shouldLock)
      setLoading(false)
    }
  }

  useEffect(() => {
    checkLocked()
  }, [rest?.computedMatch?.params, loadingFailed])

  if (loadingFailed) return <LoginModal />
  if (!resActive && (loadingSubs || loading || (!isPremiumSubscribed && loadingSubs))) return <Loader />

  // redirect to home if the user is not admin and is trying to access
  // any of the korean courses or chinese
  const course = rest?.computedMatch?.params?.course
  if (!isStaffRole && (['cfz1'].includes(course))) {
    return <Redirect to="/" />
  }

  // disallow sub access if locked, not a staff role, and not subscribed
  if (isLocked && !isStaffRole && !isPremiumSubscribed && !isSubbed) {
    // console.log('Disallowing access')
    return <Redirect to='/memberships/premium/subscribe' />
  }

  // if we're not staff and try to access jfz1 after japanese1 (v8) is live, we have to
  // redirect to it (only if we're at a courses route)
  // so e.g., /courses/jfz1/1/1 -> /courses/japanese1/6/1
  // (lessons are +5 in japanese1 from jfz1)
  // prelessons need to be converted to a number (A-E mapping to 1-5)
  const v8Redirect = false
  if (v8Redirect && course === 'jfz1') {
    const lesson = rest?.computedMatch?.params?.lesson
    const sublesson = rest?.computedMatch?.params?.sublesson || ''
    const redirectStr = '/courses/japanese1/'

    if (!lesson) return <Redirect to={redirectStr} />

    const newLesson = parseInt(lesson, 10) ? parseInt(lesson, 10) + 5 : lesson.charCodeAt(0) - 64
    return <Redirect to={`${redirectStr}${newLesson}/${sublesson}`} />
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Component {...props} />
      )}
    />
  )
}

const mapStateToPropsSubscription = (state) => ({ subs: state.auth.subs, roles: state.auth.roles })
const SubscriptionRoute = connect(mapStateToPropsSubscription, { loadSubs })(subscriptionCore)

export default SubscriptionRoute
