import React, { useContext, useEffect, useState } from 'react'
import axios from 'src/api'
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'

import { LOAD_COURSES, LOAD_SUBS } from 'src/actions/types'

import { SubscriptionContext } from 'src/contexts/SubscriptionContext'

import useAPI from 'src/hooks/useAPI'
import usePlan from 'src/hooks/subscriptions/usePlan'

type UseStripeFZProps = {
  onSuccess?: (str?: string) => void
}

const useStripeFZ = ({ onSuccess }: UseStripeFZProps) => {
  const elements = useElements()
  const stripe = useStripe()
  const [res, loading, req] = useAPI('/subscriptions/create_customer', 'POST')
  const dispatch = useDispatch()
  const { chosenPlan } = useContext(SubscriptionContext)
  const { planId: plan, priceId } = usePlan(chosenPlan)

  const [processing, setProcessing] = useState(false)
  const [processed, setProcessed] = useState(false)
  const [cardError, setCardError] = useState('')

  const [billingDetails, setBillingDetails] = useState({
    address: {
      city: null,
      country: null,
      line1: null,
      line2: null,
      postal_code: null,
      state: null,
    },
    email: null,
    name: null,
    phone: null,
  })

  useEffect(() => {
    req()
  }, [])

  const handlePaymentThatRequiresCustomerAction = ({
    subscription,
    paymentMethodId,
    _priceId,
    invoice,
    isRetry
  }) => {
    console.log('subscription', subscription)
    console.log('payment method id', paymentMethodId)
    console.log('price id', _priceId)
    console.log('invoice', invoice)
    console.log('is retry', isRetry)
    return ({ subscription, paymentMethodId, priceId: _priceId, invoice, isRetry, _priceId })
  }

  const handleRequiresPaymentMethod = ({ subscription, paymentMethodId, _priceId }) => {
    if (subscription.status === 'active') {
      return ({ subscription, priceId: _priceId, paymentMethodId })
    }

    if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
      localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status)
      setCardError('Your card was declined.')
      throw new Error('Your card was declined.')
    }

    return ({ subscription, priceId: _priceId, paymentMethodId })
  }

  const onSubscriptionComplete = (_res) => {
    if (_res.subscription.status === 'active') {
      axios.get('/user/get_courses').then((res2) => {
        if (res2.data.success) {
          dispatch({ type: LOAD_COURSES, payload: res2.data.courses })
          dispatch({ type: LOAD_SUBS, payload: res2.data.subs })
          onSuccess?.(plan)
          setProcessing(false)
          setProcessed(false)
        }
      })
    }
  }

  const retryInvoiceWithNewPaymentMethod = ({
    paymentMethodId,
    invoiceId,
    _priceId,
    last4,
    brand,
  }) => {
    axios
      .post('/subscriptions/retry_invoice', {
        paymentMethodId,
        invoiceId,
        priceId: _priceId,
        last4,
        brand,
        plan,
      })
      .then((_res) => {
        console.log('res', _res)
        if (!_res.data.success) throw _res
        return _res
      })
      .then((_res) => {
        console.log('res', _res)
        return {
          invoice: _res.data.invoice,
          paymentMethodId,
          priceId,
          isRetry: true,
          subscription: _res.data.sub,
          _priceId: priceId
        }
      })
      .then(handlePaymentThatRequiresCustomerAction)
      .then(onSubscriptionComplete)
      .catch((err) => {
        console.log('err', err)
        setCardError(err.toString())
        setProcessing(false)
        setProcessed(true)
      })
  }

  const showCardError = (err) => {
    setCardError(err.toString())
  }

  const createSubscription = ({ paymentMethodId, _priceId, last4, brand }) => {
    axios
      .post('/subscriptions/create_subscription', {
        paymentMethodId,
        priceId: _priceId,
        last4,
        brand,
        plan,
      })
      .then((_res) => {
        console.log('res', _res)
        if (!_res.data.success) throw _res
        return _res
      })
      .then((_res) => {
        console.log('res', _res)
        return {
          paymentMethodId,
          _priceId,
          subscription: _res.data.sub,
          invoice: _res.data.invoice,
          isRetry: false,
        }
      })
      .then(handlePaymentThatRequiresCustomerAction)
      .then(handleRequiresPaymentMethod)
      .then(onSubscriptionComplete)
      .catch((err) => {
        console.log(err)
        showCardError(
          'Sorry, there was an error processing your card. Please try another payment method.'
        )
        setProcessing(false)
        setProcessed(true)
      })
  }

  const processCard = async (ev) => {
    setCardError('')
    ev.preventDefault()

    if (!stripe || !elements || processing || processed || !billingDetails.name) return

    setProcessing(true)

    const cardElement = elements.getElement(CardElement)
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    )

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billingDetails,
    })

    if (error) {
      console.log('[createPaymentMethod error]', error)
      setProcessing(false)
      setCardError(error.message)
    } else {
      const paymentMethodId = paymentMethod.id
      const last4 = paymentMethod.card?.last4
      const brand = paymentMethod.card?.brand

      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        const invoiceId = localStorage.getItem('latestInvoiceId')
        retryInvoiceWithNewPaymentMethod({
          paymentMethodId,
          invoiceId,
          _priceId: priceId,
          last4,
          brand,
        })
      } else {
        createSubscription({ paymentMethodId, _priceId: priceId, last4, brand })
      }
    }
  }

  return { processCard, stripe, processing, processed, cardError, billingDetails, setBillingDetails }
}

export default useStripeFZ
